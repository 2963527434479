<div class="w-100">
    <div *ngIf="!showDisclaimer" class="boxes">
        <div class="navbar">
            <div class="d-flex justify-content-end w-100">
                <span class="close-icon" style="cursor: pointer;" (click)="close()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                        <g opacity="0.5">
                          <path d="M1 1.5L11 11.5" stroke="#3C4082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M11 1.5L1 11.5" stroke="#3C4082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                      </svg>
                </span>
            </div>
            <div class="d-flex justify-content-between align-items-start w-100 mt-4">
                <span class="d-block pink-title">Tu cuenta necesita verificación</span>
            </div>
        </div>
        <div class="content main-section w-100">
            <div class="main-section w-100 flex-column">
                <div class="content d-flex flex-column align-items-center w-100">
                    <div class="img-container">
                        <img class="w-100" src="assets/img/illustrations/im_verificar.png" srcset="assets/img/illustrations/im_verificar.png 1x, assets/img/illustrations/im_verificar@2x.png 2x" alt="verificar">
                    </div>
                    <span class="d-block mb-3 secundary-text">Te guiaremos en el proceso</span>
                    <button *ngIf="soyIoEnabled" (click)="openSoyIO()" class="neat-btn neat-btn__primary w-100">
                        <span>
                            Comenzar
                        </span>
                    </button>
                    <button *ngIf="!soyIoEnabled" (click)="openIntercom()" class="neat-btn neat-btn__primary w-100">
                        <span>
                            Ir al chat
                        </span>
                    </button>
                    <span *ngIf="soyIoEnabled" class="d-block mt-3 gray-text"> 
                        <img src="assets/img/illustrations/lg_soyio.png" srcset="assets/img/illustrations/lg_soyio.png 1x, assets/img/illustrations/lg_soyio@2x.png 2x" alt="soyio"> | Utilizamos 
                    <span class="f-700">soyio.id</span> para validar identidad</span>
                </div>
                <div class="footer d-flex justify-content-center align-items-center w-100 py-2">
                    <span class="d-block my-3 link-text" (click)="showOrHideDisclaimer()">🤔 ¿Por qué necesitan esto? </span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showDisclaimer" class="boxes">
        <div class="navbar">
            <div class="d-flex justify-content-between align-items-center w-100">
                <span class="summary-main-title mr-auto">
                    Necesitamos verificarte
                </span>
                <span class="close-icon d-flex justify-content-end mb-1" style="cursor: pointer;" (click)="showOrHideDisclaimer()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                        <g opacity="0.5">
                          <path d="M1 1.5L11 11.5" stroke="#3C4082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M11 1.5L1 11.5" stroke="#3C4082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                      </svg>
                </span>
            </div>
        </div>
        <div class="content main-section w-100">
            <div class="main-section w-100 flex-column">
                <div class="content d-flex flex-column align-items-center w-100">
                    <div class="p-2">
                        <div class="mt-3 detail-box p-3">
                            <span class="normal-pink-text d-block mb-2">
                                ¿Por qué?
                            </span>
                            <ul class="mb-0 pl-3">
                                <li>
                                    <span class="normal-blue-text">
                                        Nos interesa verificar que tu eres quien dices ser, porque nos ayuda a prevenir suplantación de identidad y fraudes.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-3 detail-box p-3">
                            <span class="normal-pink-text d-block mb-2">
                                Promociones
                            </span>
                            <ul class="mb-0 pl-3">
                                <li>
                                    <span class="normal-blue-text">
                                        Algunas promociones que tenemos con partners requieren de verificación de identidad, es parte de lo que nos exigen.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-3 detail-box p-3">
                            <span class="normal-pink-text d-block mb-2">
                                Si te invitan a Neat (referidos)
                            </span>
                            <ul class="mb-0 pl-3">
                                <li>
                                    <span class="normal-blue-text">
                                        Necesitamos verificar la identidad del invitado para prevenir suplantación de identidad y fraudes.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="text-center py-3">
                            <span (click)="openIntercom()" class="link-text text-center">
                                Si tienes más dudas, escríbenos al chat
                            </span>
                        </div>
                    </div>
                </div>
                <div class="footer d-flex justify-content-center align-items-center w-100 py-2">
                    <button (click)="showOrHideDisclaimer()" class="neat-btn neat-btn__secondary w-100">
                        <span>
                            Entiendo
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>