import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { AppGlobals } from './constants';

export function getMonths(): {lastMonth: number, currentMonth: number,
  nextMonth: number, lastMonthString: string, currentMonthString: string, nextMonthString: string} {
  const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' }));
  const lastMonth: number = (today.getMonth() - 1) >= 0 ? today.getMonth() - 1 : 11;
  const currentMonth: number = today.getMonth();
  const nextMonth: number = (today.getMonth() + 1) === 12 ? 0 : today.getMonth() + 1;
  return {
    lastMonth: lastMonth,
    lastMonthString: AppGlobals.monthNames[lastMonth],
    currentMonth: currentMonth,
    currentMonthString: AppGlobals.monthNames[currentMonth],
    nextMonth: nextMonth,
    nextMonthString: AppGlobals.monthNames[nextMonth]
  };
}


export function dateDiffInDays(firstDate: Date, currentDate: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
  const utc2 = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}


export function setValidators(formGroup: FormGroup | AbstractControl, field: string) {
  formGroup.get(field).setValidators([Validators.required]);
  if (formGroup.get(field).value === null || formGroup.get(field).value === '') {
    formGroup.get(field).setErrors({ required: true });
  }
}

export function removeValidators(formGroup: FormGroup | AbstractControl, field: string) {
  formGroup.get(field).setErrors(null);
  formGroup.get(field).clearValidators();
  formGroup.get(field).markAsPristine();
  formGroup.get(field).setValue(null);
}

export function setCustomValidators(formGroup: FormGroup | AbstractControl, field: string, customValidator: ValidatorFn[]) {
  formGroup.get(field).setValidators(customValidator);
  if (formGroup.get(field).value === null || formGroup.get(field).value === '') {
    formGroup.get(field).setErrors({ required: true });
  }
}

export function softRemoveValidators(formGroup: FormGroup | AbstractControl, field: string) {
  formGroup.get(field).clearValidators();
  formGroup.get(field).setErrors(null);
}


export function truncateText(text: string, maxLength: number): string {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)  }...`;
  }
  return text;
}